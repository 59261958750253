import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import backgroundImage from "../assets/header-bg.svg";
import './header.css';

const Header = ({title, subTitle}) => (
  <header>
    <div className="banner">
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <img alt="header background" src={backgroundImage} />
    </div>
    <nav>
      <ul>
        <li>
          <Link to="/#work">
            Work
          </Link>
        </li>
        <li>
          <Link to="/#about">
            About
          </Link>
        </li>
        <li>
          <Link to="/#contact">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
}

Header.defaultProps = {
  subTitle: ``,
  title: ``,
}

export default Header
