/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, meta, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subTitle
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description;
  const title = site.siteMetadata.title;
  const subTitle = site.siteMetadata.subTitle;

  return (
    <Helmet
      htmlAttributes={{lang}}
      title={`${title} | ${subTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${subTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${subTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords.join(`, `),
        },
        ...meta
      ]}
    >
      <link rel="preconnect" href="https://youtube-nocookie.com"></link>
      <link rel="preconnect" href="https://gstatic.com"></link>
      <link rel="preconnect" href="https://www.gstatic.com"></link>
      <link rel="preconnect" href="https://google.com"></link>
      <link rel="preconnect" href="https://www.google.com"></link>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
